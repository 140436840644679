import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import P2PTable from 'components/Web_User_Interface/720p_Series/Network/P2P/p2pTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // P2P",
  "path": "/Web_User_Interface/720p_Series/Network/P2P/",
  "dateChanged": "2017-12-08",
  "author": "Mike Polinowski",
  "excerpt": "The P2P access allows you to access your camera´s live video stream via our iOS or Android app. Simply scan the P2P QR code with our app and confirm the settings - done! Please be aware, while the QR code on the camera case only works if your camera still has the default administrator login the QR code here is always generated with the actual parameters set on the camera.",
  "image": "./WebUI_720p_SearchThumb_Network_P2P.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Network_P2P.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Network Menu // P2P' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='The P2P access allows you to access your camera´s live video stream via our iOS or Android app. Simply scan the P2P QR code with our app and confirm the settings - done! Please be aware, while the QR code on the camera case only works if your camera still has the default administrator login the QR code here is always generated with the actual parameters set on the camera.' image='/images/Search/WebUI_720p_SearchThumb_Network_P2P.png' twitter='/images/Search/WebUI_720p_SearchThumb_Network_P2P.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Netzwerk/P2P/' locationFR='/fr/Web_User_Interface/720p_Series/Network/P2P/' crumbLabel="P2P" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <h3 {...{
      "id": "p2p",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#p2p",
        "aria-label": "p2p permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`P2P`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`The P2P access allows you to access your camera´s live video stream via our iOS or Android app. Simply scan the P2P QR code with our app and confirm the settings - done! Please be aware, while the QR code on the camera case only works if your camera still has the default administrator login (`}<a parentName="p" {...{
          "href": "/en/Web_User_Interface/720p_Series/System/User/"
        }}>{`please change that immediately`}</a>{`) the QR code here is always generated with the actual parameters set on the camera.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/504af773570b9063410a3f80f6113c99/6bbf7/WebUI-Network_P2P.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsSAAALEgHS3X78AAADjElEQVQ4y42STU/qaBTH+Voz8zWcyThfwBVuXLhyoybX4LuJREFeiiJVF4xUrjAgrVAUaW0LUigCty9Qal8oTwvRubuJRTP3OrO4vzw5yTnJLyc5z9/15/n579N//PTzL1O//hYMBCEIisWOw+FwKBSCIlA0GoWg8OHhoc/n29/fD4eho6OjcDgcODjw+/0uBEH29vY+fVo5OAjQNF2tVCoMwziFZpiHh4dms9l8fOQ4rlGvt1qtSqXidrtPz04LOO6KxWIwDMfjcQzDJFFsNpv1ep1rNtk6S9N0q90BAAyHQwAAsCzTNF9eXpLJZCqVqtVqrlAotLmxAUUi6XRakqSu1BUEQRRFgRfkft80TV3XtXdUVQUAYBh2dXXFMIwLRdFU6hJFsft7SpZllmUZhul0OtI7vV5PfUdRFAAAiqKZTOZVNnR9ZNtf//46Ho+fnp5omsZx/ObmplQq3d3dFYtFgiREUeQdOp2OLMsYhr3JSAJZWlryeDzJZBIAoKpPkihKUlcUJP4Lz38ROu1Ou9Xmeb4rSQIvfre5Vn24RrHbm9t2u20YxmAwUFXVMAxN09qttiR2u1JX6SuapumarqrqaDRy5OyrXCgU8vl8rVZrNDhZloGDoigwDPv8vtPT00QicXFxcXx8PBqNBoPBeDxGUTSbdeRgKOT1eiEIQhBEluXhcGjbNsuyy8vLXq93dnbW5/PNzc0FAgHLsgzD+E6ORqORSASG4WQy2e/3h8OhaZqapq2srOzu7no8noWFhe3tbQiCAAAf5VAouO/znZ+fp1KpXq83yYMgCBAE7ezsTE9Pu93u1dXVRCLxP/LZ2RkMw0fRo8+fLyebJ/Lm5ubU1NTMzMzGxsbi4mIwGLRt+6NMkvcYhuXz1xRFKYpimqZlWfV6fWtry+/3r6+vr62tzc/PT2Rd1yfXfpOz2at0On15eYnjuKaptm1bljUYDCaXe3KYfJ5lWQCA5+fnXC73JpfLRC6XQ1GUoqher8dxXK1WqzuwLEvdUwxT4Thu0larVUEQ/g1JoVD4K5PJZrOlUknXdVmWxdeESaJDo9FoPj6KTidJEs/zhmHgOP4mEwSBYVgBxymKuv8PNE1/OydJkmGYk5OTXC5H07SrWCzm89f5fL5cJhAEicViJ98AO3yYxONxgiBIknS9VoIknMcwTPUHqFQqpIOLKJN3t3ckQd6WSgRBlH+MifwPH7HZU6zLygIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/504af773570b9063410a3f80f6113c99/e4706/WebUI-Network_P2P.avif 230w", "/en/static/504af773570b9063410a3f80f6113c99/d1af7/WebUI-Network_P2P.avif 460w", "/en/static/504af773570b9063410a3f80f6113c99/04ac8/WebUI-Network_P2P.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/504af773570b9063410a3f80f6113c99/a0b58/WebUI-Network_P2P.webp 230w", "/en/static/504af773570b9063410a3f80f6113c99/bc10c/WebUI-Network_P2P.webp 460w", "/en/static/504af773570b9063410a3f80f6113c99/d8378/WebUI-Network_P2P.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/504af773570b9063410a3f80f6113c99/81c8e/WebUI-Network_P2P.png 230w", "/en/static/504af773570b9063410a3f80f6113c99/08a84/WebUI-Network_P2P.png 460w", "/en/static/504af773570b9063410a3f80f6113c99/6bbf7/WebUI-Network_P2P.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/504af773570b9063410a3f80f6113c99/6bbf7/WebUI-Network_P2P.png",
              "alt": "Web User Interface - 720p Series - Network P2P",
              "title": "Web User Interface - 720p Series - Network P2P",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <P2PTable mdxType="P2PTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Activate the p2p function to access your camera from the internet without the need for a `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding`}</a>{` in your router. Simply add the camera as a p2p camera in our `}<a parentName="p" {...{
        "href": "/en/Software/InstarVision_Overview/"
      }}>{`InstarVision`}</a>{` app, scan the QR code on the Network/P2P overlay in the WebUI or from the sticker on the camera and add the camera `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/User/"
      }}>{`Administrator Login`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS`}</a>{` is usually the better alternative to P2P, since there it offers you the direct and `}<strong parentName="p">{`full access`}</strong>{` to your camera. The disadvantage is that your router will block all traffic from the internet, that was not requested by someone on the inside of your local network - forcing you to open a door for your camera inside the router firewall (`}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`See Port Forwarding`}</a>{`).`}</p>
    <p>{`In case of the P2P service your contacts an INSTAR Relay Server in regular intervals, to check if someone requested access to live video. The next time you start your INSTAR smartphone app, your camera will find a notification to contact your smartphone immediately. Your camera will then open a connection and starts communicating with your app. This way you no longer need to open a port, since the connection always originates from inside your network. The Point-2-Point is therefore a very convenient and fast to install method to access your camera when you are away.`}</p>
    <p>{`The Relay Server only works as a mediator between your smartphone and your camera. But it can also receive your camera´s stream and forward it to your phone, in case the direct connection cannot be established. This is a fall-back safeguard to guarantee that you will be able to access your camera. The server will only log that the direct connection failed - `}<strong parentName="p">{`no video data will be saved on our server under any circumstances`}</strong>{`.`}</p>
    <p>{`You can always use the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/DDNS/"
      }}>{`DDNS Address`}</a>{` instead to avoid sending your video stream through other servers. Most routers also offer support for VPN tunnels - allowing you to access your camera fully encrypted over its local IP address - this is easily configured with a router like the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/VPN_AVM_Fritzbox/"
      }}>{`AVM Fritzbox`}</a>{` or similar.`}</p>
    <div className="successbox">
    <h3>No public IPv4 Address needed:</h3>
    <p>You can access your camera even behind an IPv6 connection or mobile AP (UMTS/LTE, with changing WAN IP) ! Use the p2p function to access your camera from the internet, when you cannot set a port forwarding for the camera necessary for the DDNS service.</p>
    </div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/5a190/P2P-QRcode.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABmklEQVQY02PQ0tGTlZVVVFRQVFL28PYXEhUXExcXFhGRlJW/dv3G//////79+////3///v3////948fXzp65d+3a03t3H16+ysDJycnAwMDIyMjAwGBtaWljbmZqaGCkr6eqqHj+7Nl/f/58+fjxx9ev3798+f3jx4fHTx49fHj50qXHjx/fu3KFgZ2DA67Z1c6uJiO9NDGhNTW1LTHx3N59T2/evHvhwuOr1x5evvLi9p1r+/atmzH9+OZNG+bOObh8GcOMCRPmzZ43bcqMNUuXZaWl8gsJiYuJyklKSggKnjl2HOTs7z/+//z578eP///+3T1/vn/SpOkzZjS0tm5YtYrh5e07fz///vTiw////7ubmhgYGHh4eNg5OFhYWc+fPw/3M8zT7z+fOvXh6tXPt2+/OXqMoaOru3fS1JauCTNmz4mLj2dgYGBmZoZ45OzZs2gB9ujGjQVz5ly9dm3turWLZkxnSE1JDo+IDIuIiouNKSwsDA0N9QsI9g8ICgkJuXv3LlwzhLx28GBLfNL+OfOaE5P70zMAnJz5AOfCVq4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99bfd616d58cd846d3599ba73e0cfbfe/e4706/P2P-QRcode.avif 230w", "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/d1af7/P2P-QRcode.avif 460w", "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/c9875/P2P-QRcode.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/99bfd616d58cd846d3599ba73e0cfbfe/a0b58/P2P-QRcode.webp 230w", "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/bc10c/P2P-QRcode.webp 460w", "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/d00b9/P2P-QRcode.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/99bfd616d58cd846d3599ba73e0cfbfe/81c8e/P2P-QRcode.png 230w", "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/08a84/P2P-QRcode.png 460w", "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/5a190/P2P-QRcode.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/99bfd616d58cd846d3599ba73e0cfbfe/5a190/P2P-QRcode.png",
              "alt": "Web User Interface - 720p Series - Network P2P",
              "title": "Web User Interface - 720p Series - Network P2P",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`There are 3 ways to connect your camera. You can choose the apps `}<strong parentName="p">{`Search function`}</strong>{` to discover the p2p camera inside your local network, `}<strong parentName="p">{`type in the User ID`}</strong>{` from the camera ´s web UI (or the sticker on the camera case) or use the `}<strong parentName="p">{`QR code scanner`}</strong>{` to scan the QR code (just hold your smartphone over the QR code - the scanner works automatically). The QR code in the web UI is created dynamically and will contain all information the app needs to set up your camera. The QR code on the sticker will contain the camera ´s default settings to allow you to quickly set up the camera. We recommend that you change the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/System/User/"
      }}>{`Administrator Password`}</a>{` for the camera and use the QR code from the web UI.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      